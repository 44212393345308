document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover({
    trigger : 'hover',
  });
  $('.download-information').popover({
    container: 'body'
  })
  
})

$(document).on('click', '.navbar-toggle, #mynavbar-close', function(){
  if ($('.nav-open').length){
    $('.perfect-scrollbar-off').removeClass('nav-open');
  }
  else{
    $('.perfect-scrollbar-off').addClass('nav-open');
  }
})
