import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the User Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  destroy(event){
    event.preventDefault();
    event.stopPropagation();
    const confirm_msg = confirm("Are you sure you want to delete this user?");

    if (confirm_msg) {
      this.stimulate('User#destroy', event.currentTarget)
        .then(() => this.message())
    }
  }

  message(){
    toastr.success('User Sucessfully Remove!', {timeOut: 50000})
  }
}
