require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require("channels")
require("jquery")
require("@nathanvda/cocoon")

import "bootstrap";
import './bootstrap_custom.js';
import "./trix-editor-overrides";
import './custom';
import "controllers";

window.jQuery = $;
window.$ = $;
global.toastr = require("toastr")