import ApplicationController from './application_controller'
export default class extends ApplicationController {

  connect () {
    super.connect()
  }

  destroy(event){
    event.preventDefault();
    event.stopPropagation();
    const confirm_msg = confirm("Are you sure you want to delete this Invoice?");

    if (confirm_msg) {
      this.stimulate('Invoice#destroy', event.currentTarget)
      .then(() => this.message())

    }
  }

  message(){
    toastr.success('Invoice Sucessfully Remove!', {timeOut: 50000})
  }
}
